import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { APIProvider } from "@vis.gl/react-google-maps";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatCard } from "blocks-react/bedrock/components/Card/Card";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatInputField } from "blocks-react/bedrock/components/InputField/InputField";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import GoogleMapLocator from "../../components/GoogleMaps/GoogleMapLocator";
import PlaceAutocomplete from "../../components/GoogleMaps/PlaceAutoComplete";
import { getGoogleMapsApiKey } from "../../lib/getEnvVariables";
import { CaasRoute } from "../../routes";
import { useCreateProjectMutate } from "../../services/query/create-project-api.query";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import { Variant } from "../GlobalNotification/GlobalNotification";
import NewProjectPendingChangesModel from "./newProjectPendingChangesModel";
const API_KEY = getGoogleMapsApiKey();
const ProjectDetailsForm = ({ activeStep, setActiveStepHandler }) => {
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [isModalActive, setIsModalActive] = useState(false);
    const CHARACTERS = 3;
    const { projectAddress, projectName, projectOwner, setProjectAddress, setProjectId, setProjectName, setProjectOwner } = useCreateNewProjectStore((state) => ({
        projectAddress: state.projectAddress,
        projectName: state.projectName,
        projectOwner: state.projectOwner,
        setProjectAddress: state.setProjectAddress,
        setProjectId: state.setProjectId,
        setProjectName: state.setProjectName,
        setProjectOwner: state.setProjectOwner
    }));
    const schema = z.object({
        owner: z.string().trim().min(CHARACTERS, { message: "Enter owner" }),
        projectAddress: z.string().trim().min(CHARACTERS, { message: "Enter Address" }),
        projectName: z.string().trim().min(CHARACTERS, { message: "Enter project name" })
    });
    const { control, formState: { errors, isDirty, isValid }, getValues, handleSubmit, setError, setValue } = useForm({
        defaultValues: {
            owner: "",
            projectAddress: "",
            projectName: ""
        },
        mode: "onBlur",
        resolver: zodResolver(schema)
    });
    const navigate = useNavigate();
    const { setGlobalNotification, userPartyNumber } = useGlobalStore((state) => ({
        setGlobalNotification: state.setGlobalNotification,
        userPartyNumber: state.userPartyNumber
    }));
    const { isError, mutate } = useCreateProjectMutate(userPartyNumber);
    useEffect(() => {
        setValue("projectAddress", selectedPlace?.formatted_address);
    }, [selectedPlace]);
    const onSubmit = (formData) => {
        mutate(formData, {
            onError: (error) => {
                const responseData = error.response?.data;
                if (error.response.status === 400 && responseData.description === "Project already exists") {
                    setError("projectName", {
                        message: "Please select another name",
                        type: "manual"
                    });
                }
                else {
                    setGlobalNotification({
                        message: `Creating new project ${projectName} has failed.Please try later`,
                        showNotification: true,
                        type: Variant.error
                    });
                    navigate(CaasRoute.Home);
                }
            },
            onSuccess: (data) => {
                setProjectId(data?.projectId);
                setActiveStepHandler(activeStep + 1);
            }
        });
    };
    const onModalClose = (modalState) => {
        setIsModalActive(modalState);
    };
    const updateFormValuesIntoStore = () => {
        if (projectName !== getValues("projectName")) {
            setProjectName(getValues("projectName"));
        }
        if (projectOwner !== getValues("owner")) {
            setProjectOwner(getValues("owner"));
        }
        if (projectAddress !== getValues("projectAddress")) {
            setProjectAddress(getValues("projectAddress"));
        }
    };
    //setting project name and owner and project address into store
    useEffect(() => {
        if (isValid) {
            updateFormValuesIntoStore();
        }
        else if (isDirty) {
            //when user removed the entered value and making it empty in that case we need to update the
            //store
            if (getValues("projectName") === "" && projectName !== getValues("projectName")) {
                setProjectName(getValues("projectName"));
            }
            if (getValues("owner") === "" && projectOwner !== getValues("owner")) {
                setProjectOwner(getValues("owner"));
            }
            if (getValues("projectAddress") === "" && projectAddress !== getValues("projectAddress")) {
                setProjectAddress(getValues("projectAddress"));
            }
        }
    });
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: "px-7 mt-2.5", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(CatHeading, { variant: "title-sm", children: "New Project Information" }), _jsx(CatHeading, { variant: "footnote", children: "Please complete the project work site information to create a new record." })] }), _jsxs("div", { className: "flex h-96 gap-2 my-3.5", children: [_jsx("div", { className: "w-1/4", children: _jsx(CatCard, { isAutoHeight: true, removePadding: true, children: _jsxs("div", { className: "flex flex-col gap-3 bg-[#F2F2F2] p-4 rounded-lg", children: [_jsx(CatHeading, { variant: "title-sm", children: "Project Details" }), _jsx(Controller, { control: control, name: "projectName", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", fieldId: "text-field-1", label: "Project Name", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter project name", ref: ref, required: true, size: "lg", value: value, ...(errors.projectName && {
                                                        errorNote: errors.projectName.message,
                                                        status: "error"
                                                    }) })) }), _jsx(APIProvider, { apiKey: API_KEY, children: _jsx("div", { children: _jsx(PlaceAutocomplete, { control: control, errors: errors, onPlaceSelect: setSelectedPlace }) }) }), _jsx(Controller, { control: control, name: "owner", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", fieldId: "text-field-1", label: "owner", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter owner name", ref: ref, required: true, size: "lg", value: value, ...(errors.owner && {
                                                        errorNote: errors.owner.message,
                                                        status: "error"
                                                    }) })) })] }) }) }), _jsx("div", { className: "w-full h-[24rem]", children: _jsx(GoogleMapLocator, { selectedPlace: selectedPlace }) })] })] }), _jsxs("div", { className: "flex gap-1 justify-end px-7 my-2", children: [_jsx(CatButton, { onClick: () => {
                            const isCancelModalShow = !(!isDirty || !isValid);
                            if (isCancelModalShow && !isError) {
                                setIsModalActive(isCancelModalShow);
                            }
                            else {
                                navigate(CaasRoute.Home);
                            }
                        }, size: "sm", children: "Cancel" }), _jsx(CatButton, { disabled: !isDirty || !isValid, size: "sm", type: "submit", variant: "primary", children: "Next" })] }), _jsx("div", { children: _jsx(NewProjectPendingChangesModel, { onModalClose: onModalClose, showModal: isModalActive }) })] }));
};
export default ProjectDetailsForm;
