import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { APIProvider, AdvancedMarker, Map as GoogleMap, useAdvancedMarkerRef, useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";
import { getGoogleMapsApiKey } from "../../lib/getEnvVariables";
const API_KEY = getGoogleMapsApiKey();
const GoogleMapLocator = ({ selectedPlace }) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    return (_jsxs(APIProvider, { apiKey: API_KEY, children: [_jsx(GoogleMap, { className: "h-[24rem]", defaultCenter: { lat: 22.54992, lng: 0 }, defaultZoom: 1.95, 
                // disableDefaultUI={true}
                gestureHandling: "greedy", mapId: "1", children: _jsx(AdvancedMarker, { position: null, ref: markerRef }) }), _jsx(MapHandler, { marker: marker, place: selectedPlace })] }));
};
const MapHandler = ({ marker, place }) => {
    const map = useMap();
    useEffect(() => {
        if (!map || !place || !marker)
            return;
        if (place.geometry?.viewport) {
            map.fitBounds(place.geometry?.viewport);
        }
        marker.position = place.geometry?.location;
    }, [map, place, marker]);
    return null;
};
export default GoogleMapLocator;
