import { useMutation } from "@tanstack/react-query";
import { updateProjectAssetDetails } from "../api/updates-project-asset-details";
export const useUpdateProjectAssetDetails = (partyNumber, projectId, operationType) => {
    return useMutation({
        gcTime: 0,
        mutationFn: (projectAssetsUpdateRequest) => {
            return updateProjectAssetDetails(partyNumber, projectId, operationType, projectAssetsUpdateRequest);
        },
        retry: false
    });
};
